import axios from 'axios';
import Cookies from 'js-cookie';
import { api_url } from './config';

const apiClient = axios.create({
	baseURL: api_url,
});

// Add a request interceptor to include the Bearer token in every request
apiClient.interceptors.request.use((request) => {
	const accessToken = Cookies.get('accessToken');
	if (accessToken) {
		request.headers['Authorization'] = 'Bearer ' + accessToken;
	}
	return request;
});

const refreshTokenAndRetry = async (error) => {
	const originalRequest = error.config;

	if (error.response.status === 400 && originalRequest._retry === undefined) {
		originalRequest._retry = true; // mark this request as retried
		try {
			// Send the refreshToken to the server
			const response = await axios.post(`${api_url}auth/refreshToken`, {
				refreshToken: Cookies.get('refreshToken'),
			});

			if (response.data.accessToken) {
				Cookies.set('accessToken', response.data.accessToken);
				originalRequest.headers.authorization = 'Bearer ' + response.data.accessToken;
				console.log('Tokens refreshed!');
				return apiClient(originalRequest); // retry the original request with a new token
			}
		} catch (err) {
			if (err.response.status === 400) {
				console.log('Refresh token is not valid!');
				if (window.location.pathname !== '/login') {
					window.location.href = '/login';
				}
			}
		}
	}

	return Promise.reject(error);
};

apiClient.interceptors.response.use((response) => response, refreshTokenAndRetry);

export default apiClient;
