import React from 'react';
import { Select, MenuItem, InputLabel, Box } from '@mui/material';

function EmployeeDropdown({ employees, setSelectedEmployee, setEvaluationData }) {
	const handleEmployeeChange = (e) => {
		setSelectedEmployee(JSON.parse(e.target.value));
		setEvaluationData(null); // set evaluation data to null when employee is selected
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				width: '500px',
			}}>
			<InputLabel htmlFor="employee-dropdown" shrink sx={{ marginTop: 1, width: 250 }}>
				<b style={{ fontSize: 25 }}>Select an Employee:</b>
			</InputLabel>
			<Select
				labelId="employee-dropdown"
				onChange={handleEmployeeChange}
				defaultValue=""
				placeholder="Select an employee"
				displayEmpty
				//inputProps={{ notched: false }}
				inputlabelprops={{ shrink: true }}
				sx={{ marginLeft: -5, flexGrow: 1 }}>
				<MenuItem value="" disabled>
					<em style={{ color: '#d3d3d3' }}>Select an employee</em>
				</MenuItem>
				{employees.length === 0 ? (
					<MenuItem value="" disabled>
						<em style={{ color: '#d3d3d3' }}>Loading...</em>
					</MenuItem>
				) : (
					(() => {
						try {
							return employees.map((employee) => (
								<MenuItem key={employee._id} value={JSON.stringify(employee)}>
									{employee.firstName} {employee.lastName}
								</MenuItem>
							));
						} catch (error) {
							console.error('Error mapping employees:', error);
							return (
								<MenuItem value="" disabled>
									<em style={{ color: '#d3d3d3' }}>Error loading employees</em>
								</MenuItem>
							);
						}
					})()
				)}
			</Select>
		</Box>
	);
}

export default EmployeeDropdown;
