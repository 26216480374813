import React, { useEffect } from 'react';
import { Select, MenuItem, Box, InputLabel } from '@mui/material';

function PeriodDropdown({ employeeData, setSelectedPeriod, employeeSelected }) {
  const handlePeriodChange = (e) => {
    setSelectedPeriod(JSON.parse(e.target.value));
  };

  useEffect(() => {
    if (!employeeSelected || !employeeData || employeeData.periods.length === 0) {
      setSelectedPeriod(null);
    }
  }, [employeeSelected, employeeData, setSelectedPeriod]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '510px',
      }}>
      <InputLabel htmlFor="period-dropdown" shrink sx={{ marginTop: 1, width: 350 }}>
        <b style={{ fontSize: 25 }}>Select an Evaluation:</b>
      </InputLabel>
      <Select
        labelId="period-dropdown"
        onChange={handlePeriodChange}
        defaultValue=""
		value={""}
        displayEmpty
        sx={{ marginLeft: -9, flexGrow: 1 }}
      >
        {employeeSelected && employeeData && employeeData.periods.length > 0 && (
          <MenuItem value="" disabled>
            <em style={{ color: '#d3d3d3' }}>Select an Evaluation</em>
          </MenuItem>
        )}
        {employeeData === null || employeeData.length === 0 ? (
          <MenuItem value="" disabled>
            {!employeeSelected ? (
              <em style={{ color: '#d3d3d3' }}>Select Employee First</em>
            ) : employeeData === null ? (
              <em style={{ color: '#d3d3d3' }}>Loading...</em>
            ) : (
              <em style={{ color: '#d3d3d3' }}>No Evaluations to show</em>
            )}
          </MenuItem>
        ) : (
          employeeData.periods.map((period) => (
            <MenuItem key={period._id} value={JSON.stringify(period._id)}>
              {period.startDate}
            </MenuItem>
          ))
        )}
      </Select>
    </Box>
  );
}

export default PeriodDropdown;
