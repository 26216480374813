import React from 'react';

function EvaluationEmployee({ evaluationData }) {
	return (
		<div>
			<div style={{ display: 'flex', minHeight: 30, paddingBottom: 15 }}>
				<div style={{ flex: 0.4 }}>
					<b>Period : </b>
					{evaluationData.startDate}
					<br />
					<b>Score : </b>
					{evaluationData.totalScore}
				</div>
				<div style={{ flex: 1 }}>
					<b>Notes : </b>
					{evaluationData.notes}
				</div>
			</div>
		</div>
	);
}

export default EvaluationEmployee;
