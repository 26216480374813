import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import apiClient from '../API/apiClient';

const valueStreams = [
	'Orders',
	'Pricing Engine',
	'Product Data Tiers',
	'BEES Hub',
	'Store Management',
	'Digital Identity',
	'Core',
];

const NewEmployeeModal = ({ open, handleClose, onEmployeeCreated }) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [job, setJob] = useState('');
	const [band, setBand] = useState('');
	const [valueStream, setValueStream] = useState('');
	const [team, setTeam] = useState('');
	const [careerTarget, setCareerTarget] = useState('');

	const [jobs, setJobs] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [skillsIds, setSkillsIds] = useState([]);

	useEffect(() => {
		apiClient
			.get('jobs')
			.then((response) => {
				setJobs(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		fetchSkills();
	}, []);

	const handleSubmit = async () => {
		const employee = {
			firstName,
			lastName,
			job,
			band,
			valueStream,
			team,
			careerTarget,
			skills: skillsIds,
		};

		try {
			await apiClient.post('employees', employee);
			handleSnackbarOpen('success', 'Employee Created Successfully');
			resetForm();
			onEmployeeCreated(); // call the callback function here
			handleClose();
		} catch (error) {
			handleSnackbarOpen('error', 'Error Creating Employee');
			console.error(error);
		}
	};

	const resetForm = () => {
		setFirstName('');
		setLastName('');
		setJob('');
		setBand('');
		setValueStream('');
		setTeam('');
		setCareerTarget('');
	};

	const fetchSkills = async () => {
		try {
			const response = await apiClient.get('skills');
			const ids = response.data.map((skill) => skill._id);
			setSkillsIds(ids);
		} catch (error) {
			console.error(error);
		}
	};

	// Handle Snackbar
	const handleSnackbarOpen = (severity, message) => {
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
		setSnackbarMessage(message);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const modalBody = (
		<Box sx={{ ...styles.modal }}>
			<h2>Create New Employee</h2>
			<TextField
				label="First Name"
				variant="outlined"
				fullWidth
				value={firstName}
				onChange={(e) => setFirstName(e.target.value)}
				sx={{ marginBottom: 2 }}
			/>
			<TextField
				label="Last Name"
				variant="outlined"
				fullWidth
				value={lastName}
				onChange={(e) => setLastName(e.target.value)}
				sx={{ marginBottom: 2 }}
			/>
			<TextField
				select
				label="Job"
				variant="outlined"
				fullWidth
				value={job}
				onChange={(e) => setJob(e.target.value)}
				sx={{ marginBottom: 2 }}>
				{jobs.map((jobItem) => (
					<MenuItem key={jobItem._id} value={jobItem._id}>
						{jobItem.jobName}
					</MenuItem>
				))}
			</TextField>
			<TextField
				select
				label="Band"
				variant="outlined"
				fullWidth
				value={band}
				onChange={(e) => setBand(e.target.value)}
				sx={{ marginBottom: 2 }}>
				{[...Array(9).keys()].map((bandValue) => (
					<MenuItem key={bandValue} value={bandValue}>
						{bandValue}
					</MenuItem>
				))}
			</TextField>
			<TextField
				select
				label="Value Stream"
				variant="outlined"
				fullWidth
				value={valueStream}
				onChange={(e) => setValueStream(e.target.value)}
				sx={{ marginBottom: 2 }}>
				{valueStreams.map((stream) => (
					<MenuItem key={stream} value={stream}>
						{stream}
					</MenuItem>
				))}
			</TextField>
			<TextField
				label="Team"
				variant="outlined"
				fullWidth
				value={team}
				onChange={(e) => setTeam(e.target.value)}
				sx={{ marginBottom: 2 }}
			/>
			<TextField
				select
				label="Career Target"
				variant="outlined"
				fullWidth
				value={careerTarget}
				onChange={(e) => setCareerTarget(e.target.value)}
				sx={{ marginBottom: 2 }}>
				{jobs.map((jobItem) => (
					<MenuItem key={jobItem._id} value={jobItem._id}>
						{jobItem.jobName}
					</MenuItem>
				))}
			</TextField>
			<Button variant="contained" color="primary" onClick={handleSubmit}>
				Save
			</Button>
			<Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 1 }}>
				Cancel
			</Button>
		</Box>
	);

	return (
		<>
			<Modal open={open} onClose={handleClose}>
				{modalBody}
			</Modal>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

const styles = {
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		maxWidth: 500,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	},
};

export default NewEmployeeModal;
