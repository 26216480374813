import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import apiClient from '../API/apiClient';

const DeleteEmployeeModal = ({ open, handleClose, selectedEmployee, onEmployeeDeleted }) => {
	const [jobs, setJobs] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [snackbarMessage, setSnackbarMessage] = useState('');

	useEffect(() => {
		apiClient
			.get('jobs')
			.then((response) => {
				setJobs(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const getJobName = (jobId) => {
		const job = jobs.find((jobItem) => jobItem._id === jobId);
		return job ? job.jobName : 'Unknown';
	};

	const handleDeleteEmployee = async () => {
		try {
			await apiClient.delete('employees', {
				data: { id: selectedEmployee._id },
			});
			handleSnackbarOpen('success', 'Employee Deleted Successfully');
			onEmployeeDeleted();
			handleClose();
		} catch (error) {
			handleSnackbarOpen('error', 'Error Delecting Employee');
			console.error(error);
		}
	};

	// Handle Snackbar
	const handleSnackbarOpen = (severity, message) => {
		setSnackbarSeverity(severity);
		setSnackbarOpen(true);
		setSnackbarMessage(message);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const deleteModalBody = (
		<Box sx={{ ...styles.modal }}>
			<h2>Are you sure you want to delete the following employee?</h2>
			{selectedEmployee && (
				<>
					<h3>
						{selectedEmployee.firstName} {selectedEmployee.lastName}
					</h3>
					<p>
						<b>Job: </b>
						{getJobName(selectedEmployee.job)}
					</p>
					<p>
						<b>Band: </b>
						{selectedEmployee.band}
					</p>
					<p>
						<b>Value Stream: </b>
						{selectedEmployee.valueStream}
					</p>
				</>
			)}
			<Button variant="contained" color="primary" onClick={handleDeleteEmployee}>
				Yes
			</Button>
			<Button variant="contained" color="secondary" onClick={handleClose} sx={{ marginLeft: 1 }}>
				No
			</Button>
		</Box>
	);

	return (
		<>
			<Modal open={open} onClose={handleClose}>
				{deleteModalBody}
			</Modal>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

const styles = {
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		maxWidth: 500,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	},
};

export default DeleteEmployeeModal;
