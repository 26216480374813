// Import necessary modules from React, React Router and other libraries
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// Import custom components
import Main from './Components/Main';
import Login from './Components/Auth/Login';

// Import API client for making requests to the backend
import apiClient from './Components/API/apiClient';

// Define the main App component
function App() {
	// Declare state variable to manage user authentication status
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	// Function to handle successful login
	const handleLoginSuccess = () => {
		setIsAuthenticated(true);
	};

	// Function to validate access tokens stored in cookies
	const validateTokens = async () => {
		const accessToken = Cookies.get('accessToken');
		if (accessToken) {
			try {
				const response = await apiClient.post('auth/test');
				if (response.status === 200) {
					setIsAuthenticated(true);
					return;
				}
			} catch (err) {
				console.log('Access token is not valid!');
			}
		}

		setIsAuthenticated(false);
	};

	// Call validateTokens function when the component mounts
	useEffect(() => {
		validateTokens();
	}, []);

	// Render the application with proper routing based on authentication status
	return (
		<Router>
			<Routes>
				{/* Route for the main page */}
				<Route path="/" element={isAuthenticated ? <Main /> : <Navigate to="/login" />} />

				{/* Route for the login page */}
				<Route
					path="/login"
					element={
						isAuthenticated ? <Navigate to="/" /> : <Login onLoginSuccess={handleLoginSuccess} />
					}
				/>
			</Routes>
		</Router>
	);
}

// Export the App component as default
export default App;
