import React, { useState, useEffect } from 'react';
import './Main.css';
import apiClient from './API/apiClient';
import Cookies from 'js-cookie';

import EmployeeDropdown from './Sub/EmployeeDropdown';
import PeriodDropdown from './Sub/PeriodDropdown';
import EvaluationEmployee from './Sub/EvaluationEmployee';
import EmployeeDetails from './Sub/EmployeeDetails';
import EvaluationPeriodDetails from './Sub/EvaluationPeriodDetails';
import NewEmployeeModal from './Sub/NewEmployeeModal';
import EditEmployeeModal from './Sub/EditEmployeeModal';
import DeleteEmployeeModal from './Sub/DeleteEmployeeModal';
import NewEvaluationModal from './Sub/NewEvaluationModal';
import RadarChart from './Sub/RadarChart';
import SkillsScatterChart from './Sub/SkillsScatterChart';

import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

function Main() {
	const [employees, setEmployees] = useState([]);
	const [userData, setUserData] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [employeeData, setEmployeeData] = useState(null);
	const [selectedPeriod, setSelectedPeriod] = useState(null);
	const [evaluationData, setEvaluationData] = useState(null);
	const [updateCounter, setUpdateCounter] = useState(0);
	const [updateEvalCounter, setUpdateEvalCounter] = useState(0);
	const [skillTypes, setSkillTypes] = useState({});

	// Modals
	const [editEmployeeModalOpen, setEditEmployeeModalOpen] = useState(false);
	const [newEmployeeModalOpen, setNewEmployeeModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [newEvaluationOpen, setNewEvaluationOpen] = useState(false);

	// Fetch the list of employees from the API
	useEffect(() => {
		apiClient
			.get('employees')
			.then((response) => {
				setEmployees(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [updateCounter]);

	useEffect(() => {
		apiClient
			.get('auth/me')
			.then((response) => {
				setUserData(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	// Fetch the employee data when a new employee is selected
	useEffect(() => {
		if (selectedEmployee) {
			apiClient
				.get(`evaluations/${selectedEmployee._id}_2023`)
				.then((response) => {
					setEmployeeData(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [selectedEmployee, updateEvalCounter]);

	// Fetch the evaluation data when a new period is selected
	useEffect(() => {
		if (selectedPeriod && selectedEmployee) {
			apiClient
				.get(`evaluations/${selectedEmployee._id}_2023/${selectedPeriod}`)
				.then((response) => {
					setEvaluationData(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [selectedPeriod, selectedEmployee]);

	// Fetch the skill types for the evaluation data
	useEffect(() => {
		if (evaluationData && evaluationData.evaluatedSkills) {
			const fetchSkillTypes = async () => {
				const skillTypes = {};
				for (const skill of evaluationData.evaluatedSkills) {
					const response = await apiClient.get('skills/' + skill.skillID);
					skillTypes[skill.skillID] = response.data.type;
				}
				setSkillTypes(skillTypes);
			};
			fetchSkillTypes();
		}
	}, [evaluationData]);

	// Add the following functions to handle the modal open and close events
	const handleNewEmployeeModalOpen = () => {
		setNewEmployeeModalOpen(true);
	};

	const handleNewEmployeeModalClose = () => {
		setNewEmployeeModalOpen(false);
	};

	const handleEditEmployeeModalOpen = () => {
		setEditEmployeeModalOpen(true);
	};

	const handleEditEmployeeModalClose = () => {
		setEditEmployeeModalOpen(false);
	};

	const handleOpenDeleteModal = () => {
		setDeleteModalOpen(true);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const handleNewEvaluationOpen = () => {
		setNewEvaluationOpen(true);
	};

	const handleNewEvaluationClose = () => {
		setNewEvaluationOpen(false);
	};

	const handleEmployeeUpdateCounter = () => {
		setUpdateCounter(updateCounter + 1);
		setSelectedEmployee(null);
		setSelectedPeriod(null);
		setEvaluationData(null);
	};

	const handleEvaluationUpdateCounter = () => {
		setUpdateEvalCounter(updateEvalCounter + 1);
		//setSelectedEmployee(null);
		setSelectedPeriod(null);
		setEvaluationData(null);
	};

	// Generate data for the RadarChart component
	const getRadarChartData = (skills, skillType) => {
		try {
			if (!Array.isArray(skills)) throw new Error('Skills must be an array');
			if (typeof skillType !== 'string') throw new Error('Skill type must be a string');

			return skills
				.filter((skill) => skillTypes[skill.skillID] === skillType)
				.map((skill) => ({
					skillName: skill.skillName,
					rating: parseFloat(skill.rating),
				}));
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	const handleLogout = async () => {
		try {
			const response = await apiClient.post('auth/logout', {
				refreshToken: Cookies.get('refreshToken'),
			});

			if (response.status === 200) {
				console.log(response.data.message);

				// Clear tokens from cookies after successful logout
				Cookies.remove('accessToken');
				Cookies.remove('refreshToken');

				// Redirect the user to the login page.
				window.location.href = '/login';
			} else {
				console.log('Something went wrong while logging out.');
			}
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			} else {
				console.error(error);
			}
		}
	};

	return (
		<div className="container">
			<div className="header-area">
				<div className="heading">
					<h1>Employee Performance Evaluation ({process.env.REACT_APP_ENV})</h1>
				</div>
				<div className="menu">
					<h3>
						Welcome {userData.firstName} {userData.lastName}
					</h3>
					<Button variant="contained" color="secondary" onClick={handleLogout}>
						Logout
					</Button>
				</div>
			</div>
			<div className="search-area">
				<div className="emp-select">
					<EmployeeDropdown
						key={updateCounter}
						employees={employees}
						setSelectedEmployee={setSelectedEmployee}
						setEvaluationData={setEvaluationData}
					/>
					<div className="create-employee-button">
						<IconButton variant="contained" color="primary" onClick={handleNewEmployeeModalOpen}>
							<AddIcon />
						</IconButton>
						&nbsp;
						<IconButton
							variant="contained"
							color="secondary"
							onClick={handleEditEmployeeModalOpen}
							disabled={!selectedEmployee}>
							<EditIcon />
						</IconButton>
						&nbsp;
						<IconButton
							variant="contained"
							color="error"
							onClick={handleOpenDeleteModal}
							disabled={!selectedEmployee}>
							<DeleteIcon />
						</IconButton>
						{/* <button onClick={handleEmployeeUpdateCounter}>Update Employees</button> */}
					</div>
				</div>
				<div className="eval-select">
					<PeriodDropdown
						key={updateCounter + updateEvalCounter}
						employeeData={employeeData}
						setSelectedPeriod={setSelectedPeriod}
						employeeSelected={selectedEmployee !== null}
					/>
					<div className="create-employee-button">
						<IconButton
							variant="contained"
							color="primary"
							onClick={handleNewEvaluationOpen}
							disabled={!selectedEmployee}>
							<AddIcon />
						</IconButton>
					</div>
				</div>
			</div>
			<div className="employee-area">
				<div className="emp-details">
					<h2>Employee Details</h2>
					{selectedEmployee && <EmployeeDetails selectedEmployee={selectedEmployee} />}
				</div>
				<div className="eval-details">
					<h2>Evaluation Details</h2>
					{evaluationData && <EvaluationEmployee evaluationData={evaluationData} />}
					{evaluationData && <EvaluationPeriodDetails evaluationData={evaluationData} />}
				</div>
			</div>
			<div className="report-area">
				{evaluationData && (
					<>
						{Object.values(skillTypes).includes('Soft') && (
							<RadarChart
								data={getRadarChartData(evaluationData.evaluatedSkills, 'Soft')}
								title="Soft Skills"
							/>
						)}
						{Object.values(skillTypes).includes('Hard') && (
							<RadarChart
								data={getRadarChartData(evaluationData.evaluatedSkills, 'Hard')}
								title="Hard Skills"
							/>
						)}
					</>
				)}
				{evaluationData && (
					<>
						<SkillsScatterChart data={evaluationData} />
					</>
				)}
			</div>

			<div className="footer-area">Footer</div>

			{/* Modals */}
			<NewEmployeeModal
				open={newEmployeeModalOpen}
				handleClose={handleNewEmployeeModalClose}
				onEmployeeCreated={handleEmployeeUpdateCounter}
			/>
			{selectedEmployee && (
				<EditEmployeeModal
					key={selectedEmployee._id}
					open={editEmployeeModalOpen}
					handleClose={handleEditEmployeeModalClose}
					selectedEmployee={selectedEmployee}
					onEmployeeUpdated={handleEmployeeUpdateCounter}
				/>
			)}
			{selectedEmployee && (
				<DeleteEmployeeModal
					open={deleteModalOpen}
					handleClose={handleCloseDeleteModal}
					selectedEmployee={selectedEmployee}
					onEmployeeDeleted={handleEmployeeUpdateCounter}
				/>
			)}
			{selectedEmployee && (
				<NewEvaluationModal
					open={newEvaluationOpen}
					handleClose={handleNewEvaluationClose}
					selectedEmployee={selectedEmployee}
					onEvaluationCreated={handleEvaluationUpdateCounter}
				/>
			)}
		</div>
	);
}

console.log(
	`%c${'Employee Performance Evaluation Application running (' + process.env.REACT_APP_ENV})`,
	'color: green; font-size: 12px;'
);

export default Main;
