import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';

const RadarChart = ({ data, title }) => {
	return (
		<div className="radarBox">
			<h3>{title}</h3>
			<ResponsiveRadar
				data={data}
				keys={['rating']}
				indexBy="skillName"
				maxValue="5"
				margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
				curve="linearClosed"
				borderWidth={3}
				borderColor={{ from: 'color' }}
				gridLevels={5}
				gridShape="circular"
				gridLabelOffset={10}
				enableDots={true}
				dotSize={10}
				dotColor={{ theme: 'background' }}
				dotBorderWidth={5}
				dotBorderColor={{ from: 'color' }}
				enableDotLabel={true}
				dotLabel="rating"
				dotLabelYOffset={-12}
				colors={{ scheme: 'nivo' }}
				fillOpacity={0.25}
				blendMode="multiply"
				animate={true}
				motionConfig="wobbly"
				isInteractive={true}
				tooltip={({ id, value, color }) => (
					<strong style={{ color }}>
						{id}: {value}
					</strong>
				)}
				theme={{
					tooltip: {
						container: {
							background: '#d47624',
						},
					},
				}}
			/>
		</div>
	);
};

export default RadarChart;
