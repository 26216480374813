import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import apiClient from '../API/apiClient';

function NewEvaluationModal({ open, handleClose, selectedEmployee, onEvaluationCreated }) {
	const [skills, setSkills] = useState([]);
	const [evaluatedSkills, setEvaluatedSkills] = useState([]);
	const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
	const [notes, setNotes] = useState('');
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [snackbarMessage, setSnackbarMessage] = useState('');

	useEffect(() => {
		const fetchSkills = async () => {
			const skillIds = selectedEmployee.skills;
			if (skillIds && skillIds.length > 0) {
				try {
					const fetchedSkills = await Promise.all(
						skillIds.map(async (id) => {
							const response = await apiClient.get('skills/' + id);
							return response.data;
						})
					);
					setSkills(fetchedSkills);
					setEvaluatedSkills(
						fetchedSkills.map((skill) => ({
							skillID: skill._id,
							skillName: skill.name,
							rating: '',
							comments: '',
							skillType: skill.type,
						}))
					);
				} catch (error) {
					console.error(error);
				}
			}
		};

		fetchSkills();
	}, [selectedEmployee]);

	const handleDateChange = (event) => {
		setDate(event.target.value);
	};

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
	};

	const handleRatingChange = (index, event) => {
		const newEvaluatedSkills = [...evaluatedSkills];
		newEvaluatedSkills[index].rating = event.target.value;
		setEvaluatedSkills(newEvaluatedSkills);
	};

	const handleCommentsChange = (index, event) => {
		const newEvaluatedSkills = [...evaluatedSkills];
		newEvaluatedSkills[index].comments = event.target.value;
		setEvaluatedSkills(newEvaluatedSkills);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const handleSnackbarOpen = (severity, message) => {
		setSnackbarSeverity(severity);
		setSnackbarMessage(message);
		setSnackbarOpen(true);
	};

	const handleSubmit = async () => {
		const newEvaluation = {
			employeeID: selectedEmployee._id,
			employeeName: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
			periods: [
				{
					startDate: date,
					evaluatedSkills,
					notes,
				},
			],
		};

		try {
			await apiClient.post('evaluations', newEvaluation);
			handleSnackbarOpen('success', 'Evaluation created successfully');
			onEvaluationCreated();
			resetForm();
			handleClose();
		} catch (error) {
			handleSnackbarOpen('error', 'Failed to create evaluation');
			console.error(error);
		}
	};

	const resetForm = () => {
		setEvaluatedSkills(
			skills.map((skill) => ({
				skillID: skill._id,
				skillName: skill.name,
				rating: '',
				comments: '',
				type: skill.type,
			}))
		);
		setDate(new Date().toISOString().split('T')[0]);
		setNotes('');
	};

	const modalBody = (
		<Box sx={{ ...styles.modal }}>
			<Box sx={{ overflowY: 'auto', maxHeight: 'calc(100% - 48px)' }}>
				<h2>
					New Evaluation for {selectedEmployee.firstName} {selectedEmployee.lastName}
				</h2>
				<TextField
					label="Date"
					type="date"
					fullWidth
					defaultValue={date}
					onChange={handleDateChange}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					label="Notes"
					multiline
					rows={3}
					fullWidth
					margin="normal"
					variant="outlined"
					value={notes}
					onChange={handleNotesChange}
				/>
				{skills.map((skill, index) => (
					<div key={skill._id}>
						<h3>{skill.name}</h3>
						<p>
							<b>Type: </b>
							{skill.type} Skill
						</p>
						<p>
							<b>Definition: </b>
							{skill.definition}
						</p>
						<p>
							<b>Measure: </b>
							{skill.measure}
						</p>
						<InputLabel id="rating-label">Rating</InputLabel>
						<Select
							labelId="rating-label"
							label="Rating"
							fullWidth
							variant="outlined"
							value={evaluatedSkills[index].rating}
							onChange={(event) => handleRatingChange(index, event)}
							displayEmpty>
							<MenuItem value="" disabled>
								<em>Choose a rating</em>
							</MenuItem>
							<MenuItem value={1}>Very Poor</MenuItem>
							<MenuItem value={2}>Poor</MenuItem>
							<MenuItem value={3}>Good</MenuItem>
							<MenuItem value={4}>Very Good</MenuItem>
							<MenuItem value={5}>Excellent</MenuItem>
						</Select>
						<TextField
							label="Comments"
							fullWidth
							multiline
							rows={3}
							variant="outlined"
							value={evaluatedSkills[index].comments}
							onChange={(event) => handleCommentsChange(index, event)}
							sx={{ marginBottom: 2, marginTop: 2 }}
						/>
					</div>
				))}
				<Button variant="contained" color="primary" onClick={handleSubmit}>
					Save
				</Button>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => {
						resetForm();
						handleClose();
					}}
					sx={{ marginLeft: 1 }}>
					Cancel
				</Button>
			</Box>
		</Box>
	);

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="new-evaluation-modal-title"
				aria-describedby="new-evaluation-modal-description">
				{modalBody}
			</Modal>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

const styles = {
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		maxWidth: 600,
		maxHeight: '80%', // Set a maxHeight to limit the modal height
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		overflowY: 'auto', // Enable vertical scrolling
	},
};

export default NewEvaluationModal;
