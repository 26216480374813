import React, { useState, useEffect } from 'react';
import apiClient from '../API/apiClient';

function EmployeeDetails({ selectedEmployee }) {
	const [jobs, setJobs] = useState([]);

	useEffect(() => {
		apiClient
			.get('jobs')
			.then((response) => {
				setJobs(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const getJobName = (jobId) => {
		const job = jobs.find((jobItem) => jobItem._id === jobId);
		return job ? job.jobName : 'Unknown';
	};

	return (
		<div>
			{/* Paragraphs for employee details which include Name, Job, Band, Value Stream, Team, CareerTarget */}

			<div style={{ display: 'flex', height: 30 }}>
				<div style={{ flex: 1 }}>
					<b>Employee Name: </b>
					{selectedEmployee.firstName} {selectedEmployee.lastName}
				</div>
				<div style={{ flex: 1 }}>
					<b>Band: </b>
					{selectedEmployee.band}
				</div>
			</div>

			<div style={{ display: 'flex', height: 30 }}>
				<div style={{ flex: 1 }}>
					<b>Current Position: </b>
					{getJobName(selectedEmployee.job)}
				</div>
				<div style={{ flex: 1 }}>
					<b>Career Target: </b>
					{getJobName(selectedEmployee.careerTarget)}
				</div>
			</div>

			<div style={{ display: 'flex', height: 30 }}>
				<div style={{ flex: 1 }}>
					<b>Value Stream: </b>
					{selectedEmployee.valueStream}
				</div>
				<div style={{ flex: 1 }}>
					<b>Team: </b>
					{selectedEmployee.team}
				</div>
			</div>
		</div>
	);
}

export default EmployeeDetails;
