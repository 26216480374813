import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function EvaluationPeriodDetails({ evaluationData }) {
	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 500, border: 1 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ bgcolor: '#8D8B8B', fontWeight: 'bold', border: 1, width: 120 }}>
								Skill Name
							</TableCell>
							<TableCell sx={{ bgcolor: '#8D8B8B', fontWeight: 'bold', border: 1, width: 20 }} align="center">
								Rating
							</TableCell>
							<TableCell sx={{ bgcolor: '#8D8B8B', fontWeight: 'bold', border: 1, width: 300 }} align="left">
								Comment
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{evaluationData.evaluatedSkills.map((skill) => (
							<TableRow
								key={skill.skillID}
								sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
								<TableCell sx={{ border: 1 }} align="left">
									{skill.skillName}
								</TableCell>
								<TableCell sx={{ border: 1 }} align="center">
									{skill.rating}
								</TableCell>
								<TableCell sx={{ border: 1 }} align="left">
									{skill.comments}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default EvaluationPeriodDetails;
