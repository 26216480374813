import React, { useState } from 'react';
import { Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { api_url } from '../API/config';
import Cookies from 'js-cookie';

console.log(api_url);
console.log(process.env.REACT_APP_API_BASE_URL);

const StyledContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	flexGrow: 1,
}));

const StyledForm = styled('form')({
	width: '100%',
	maxWidth: 360,
});

const StyledTextField = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

function Login({ onLoginSuccess }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		axios
			.post(`${api_url}auth/login`, {
				email,
				password,
			})
			.then((res) => {
				if (res.data.tokens) {
					const { accessToken, refreshToken } = res.data.tokens; // Destructure the tokens from the response
					Cookies.set('accessToken', accessToken, { SameSite: 'Strict' }); // Save the tokens as cookies
					Cookies.set('refreshToken', refreshToken, { SameSite: 'Strict' });
					if (onLoginSuccess) {
						onLoginSuccess();
					}
				} else {
					// Handle the case when the tokens are not returned properly
					setError('Tokens not received. Please try again.');
				}
			})
			.catch((err) => {
				setError(err.response.data.message);
			});
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
				bgcolor: '#f5f5f5',
				color: '#000',
			}}>
			<CssBaseline />
			<StyledContainer>
				<Typography variant="h4" gutterBottom>
					Employee Evaluation
				</Typography>
				<StyledForm onSubmit={handleSubmit}>
					<StyledTextField
						fullWidth
						label="Email"
						type="email"
						variant="outlined"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<StyledTextField
						fullWidth
						label="Password"
						type="password"
						variant="outlined"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					{error && (
						<Typography variant="body2" color="error" gutterBottom>
							{error}
						</Typography>
					)}
					<StyledButton fullWidth type="submit" variant="contained" color="primary">
						Login
					</StyledButton>
				</StyledForm>
			</StyledContainer>
		</Box>
	);
}

export default Login;
