import React, { useEffect, useState } from 'react';
import {
	ScatterChart,
	Scatter,
	XAxis,
	YAxis,
	ZAxis,
	CartesianGrid,
	Tooltip,
	Cell,
	Label,
	ReferenceArea,
} from 'recharts';

const renderCustomizedShape = (props) => {
	const { cx, cy, payload } = props;
  
	return (
	  <g>
		<circle cx={cx} cy={cy} r={20} fill="#8884d8" />
		<text x={cx} y={cy} dy={4} textAnchor="middle" fill="#fff">{payload.z}</text>
	  </g>
	);
  };

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const SkillsScatterChart = ({ data }) => {
	const [chartData, setChartData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (Array.isArray(data.evaluatedSkills)) {
			const softSkills = data.evaluatedSkills
				.filter((skill) => skill.skillType === 'Soft')
				.reduce((acc, cur) => acc + Number(cur.rating), 0);
			const hardSkills = data.evaluatedSkills
				.filter((skill) => skill.skillType === 'Hard')
				.reduce((acc, cur) => acc + Number(cur.rating), 0);
			const totalScore = softSkills + hardSkills; // or calculate it as required
			setChartData([{ x: softSkills, y: hardSkills, z: totalScore }]);
			setIsLoading(false);
		} else {
			console.error('evaluatedSkills property is not an array');
		}
	}, [data]);
	

	if (isLoading) {
		return <div>Loading...</div>;
	}

	const xAxisTicks = Array.from({ length: 10 }, (_, i) => i * 5); // Generate an array [0, 5, 10, ..., 45]
	const yAxisTicks = Array.from({ length: 6 }, (_, i) => i * 5); // Generate an array [0, 5, 10, ..., 25]

	return (
		<ScatterChart
			width={800}
			height={400}
			margin={{
				top: 20,
				right: 20,
				bottom: 20,
				left: 20,
			}}>
			<CartesianGrid />
			<XAxis type="number" dataKey="x" name="Soft Skills" domain={[0, 45]} ticks={xAxisTicks}>
				<Label value="Soft Skills" offset={-5} position="insideBottomRight" />
			</XAxis>
			<YAxis type="number" dataKey="y" name="Hard Skills" domain={[0, 25]} ticks={yAxisTicks}>
				<Label value="Hard Skills" angle={-90} position="insideLeft" />
			</YAxis>
			<ZAxis type="number" range={[1000]} />
			<Tooltip cursor={{ strokeDasharray: '3 3' }} />

			{/* x = Soft Skills (Horizontal), y = Hard Skills (Vertical) */}

			{/* red */}
			<ReferenceArea x1={0} x2={15} y1={0} y2={5} fill="red" fillOpacity={0.3} />
			<ReferenceArea x1={0} x2={10} y1={10} y2={5} fill="red" fillOpacity={0.3} />
			<ReferenceArea x1={0} x2={5} y1={15} y2={10} fill="red" fillOpacity={0.3} />

			{/* orange */}
			<ReferenceArea x1={15} x2={25} y1={0} y2={5} fill="orange" fillOpacity={0.3} />
			<ReferenceArea x1={10} x2={20} y1={5} y2={10} fill="orange" fillOpacity={0.3} />
			<ReferenceArea x1={5} x2={15} y1={10} y2={15} fill="orange" fillOpacity={0.3} />
			<ReferenceArea x1={0} x2={10} y1={15} y2={20} fill="orange" fillOpacity={0.3} />
			<ReferenceArea x1={0} x2={5} y1={20} y2={25} fill="orange" fillOpacity={0.3} />

			{/* yellow */}
			<ReferenceArea x1={25} x2={40} y1={0} y2={5} fill="yellow" fillOpacity={0.3} />
			<ReferenceArea x1={20} x2={35} y1={5} y2={10} fill="yellow" fillOpacity={0.3} />
			<ReferenceArea x1={15} x2={30} y1={10} y2={15} fill="yellow" fillOpacity={0.3} />
			<ReferenceArea x1={10} x2={25} y1={15} y2={20} fill="yellow" fillOpacity={0.3} />
			<ReferenceArea x1={5} x2={20} y1={20} y2={25} fill="yellow" fillOpacity={0.3} />

			{/* light green */}
			<ReferenceArea x1={40} x2={45} y1={0} y2={5} fill="lightgreen" fillOpacity={0.3} />
			<ReferenceArea x1={35} x2={45} y1={5} y2={10} fill="lightgreen" fillOpacity={0.3} />
			<ReferenceArea x1={30} x2={40} y1={10} y2={15} fill="lightgreen" fillOpacity={0.3} />
			<ReferenceArea x1={25} x2={35} y1={15} y2={20} fill="lightgreen" fillOpacity={0.3} />
			<ReferenceArea x1={20} x2={30} y1={20} y2={25} fill="lightgreen" fillOpacity={0.3} />

			{/* green */}
			<ReferenceArea x1={40} x2={45} y1={10} y2={15} fill="green" fillOpacity={0.5} />
			<ReferenceArea x1={35} x2={45} y1={15} y2={20} fill="green" fillOpacity={0.5} />
			<ReferenceArea x1={30} x2={45} y1={20} y2={25} fill="green" fillOpacity={0.5} />

			<Scatter name="Skills Map" data={chartData} shape={renderCustomizedShape}>
				{chartData.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Scatter>
		</ScatterChart>
	);
};

export default SkillsScatterChart;
